import { createAction } from "redux-actions";

export const fetchMyReproductions = createAction("fetchMyReproductions");

export const fetchMyReproductionsSuccess = createAction(
  "fetchMyReproductionsSuccess"
);
export const fetchMyReproductionsFailure = createAction(
  "fetchMyReproductionsFailure"
);

export const createReproduction = createAction("createReproduction");

export const createMinimumReproduction = createAction(
  "createMinimumReproduction"
);

export const createReproductionFailure = createAction(
  "createReproductionFailure"
);

export const deleteReproduction = createAction("deleteReproduction");

export const deleteReproductionSuccess = createAction(
  "deleteReproductionSuccess"
);

export const deleteReproductionFailure = createAction(
  "deleteReproductionFailure"
);
