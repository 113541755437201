import {
  updateFields,
  updateTransposedEstimateRow,
} from "../../../actions/edit-reproductions.actions";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  Claim,
  Estimate,
  EditReproduction,
  Reproduction,
} from "../../../state/edit-reproduction";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Button,
} from "@material-ui/core";
import { questionStyles } from "../css";
import { List, Record } from "immutable";
import { DataGrid } from "../../../components/datagrid";
import QuestionWithTextField from "../components/question-with-text-field";
import QuestionWithRadioGroup from "../components/question-with-radio-group";
import QuestionWithSlider from "../components/question-with-slider";
import SaveMessage from "../../save-message";
import CSVDownloadButton from "../components/csv-download-button";
import CSVUploadButton from "../components/csv-upload-button";

const useStyles = (sectionNumber) =>
  makeStyles((theme: Theme) =>
    createStyles({
      select: {
        minWidth: 240,
      },
      estimate: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      deleteButton: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
      },
      estimateField: {
        marginRight: theme.spacing(1),
      },
      title: {
        fontSize: 14,
      },
      claim: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      noClaims: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      readOnly: {
        padding: theme.spacing(1),
        border: "1px solid",
        backgroundColor: "#f1f1f1",
      },
      ...questionStyles(sectionNumber, theme),
    })
  );

class TransposedEstimateRow extends Record({
  label: "",
  "0": "",
  "1": "",
  "2": "",
  "3": "",
  "4": "",
  "5": "",
}) {}

export const ESTIMATE_FIELDS = [
  { label: "Name of display item (e.g. Table 1, Figure S3)", field: "name" },
  { label: "Estimate", field: "estimate" },
  { label: "Standard Error", field: "standardError" },
  {
    label: "Units (e.g. %, $, log($), elasticity, standard deviations)",
    field: "units",
  },
  { label: "p-value", field: "pValue" },
  { label: "Confidence interval", field: "confidenceInterval" },
  { label: "Other Statistic", field: "otherStatistic" },
  { label: "Page", field: "page" },
  { label: "Column", field: "column" },
  { label: "Row", field: "row" },
  { label: "Inline Paragraph", field: "inlineParagraph" },
  { label: "Econometric Method", field: "econometricMethod" },
  {
    label: "Specify method (if other selected)",
    field: "otherEconometricMethod",
  },
];

// This can be replaced with Object.fromEntries in ES10
const ESTIMATE_LABEL_TO_FIELD = ESTIMATE_FIELDS.reduce(
  (labelToFieldMap, ef) => {
    labelToFieldMap[ef.label] = ef.field;
    return labelToFieldMap;
  },
  {}
);

function EditClaim({
  readOnly,
  claim,
  updateClaim,
  updateTransposedEstimateRow,
  nextClaim,
  disableNextClaim,
  index,
}: {
  readOnly: boolean;
  claim: Claim;
  updateClaim: Function;
  updateTransposedEstimateRow: Function;
  nextClaim: Function;
  disableNextClaim: boolean;
  index: number;
}) {
  const classes = useStyles(`3.${index + 1}`)();

  const onChange = (name: string) => (e: any) => {
    updateClaim(claim.merge({ [name]: e.target.value }));
  };

  // Ag Grid doesn't have good support for row level definitions,
  // so we need to set this cell editor selector on every column
  const lastRowCellEconometricEditorSelector = (params) => {
    if (ESTIMATE_FIELDS[params.rowIndex].field === "econometricMethod") {
      return {
        component: "agSelectCellEditor",
        params: {
          values: ["", "IV", "DD", "PSM", "RCT", "RDD", "OLS", "Other"],
        },
      };
    }

    return null;
  };

  const estimateColumns = [
    {
      headerName: "",
      field: "label",
      flex: 2,
      editable: false,
    },
    {
      headerName: "Preferred Specification",
      field: "0",
      flex: 1,
      cellEditorSelector: lastRowCellEconometricEditorSelector,
    },

    {
      headerName: "Alternative Spec #1",
      field: "1",
      flex: 1,
      cellEditorSelector: lastRowCellEconometricEditorSelector,
    },
    {
      headerName: "Alternative Spec #2",
      field: "2",
      flex: 1,
      cellEditorSelector: lastRowCellEconometricEditorSelector,
    },
    {
      headerName: "Alternative Spec #3",
      field: "3",
      flex: 1,
      cellEditorSelector: lastRowCellEconometricEditorSelector,
    },
    {
      headerName: "Alternative Spec #4",
      field: "4",
      flex: 1,
      cellEditorSelector: lastRowCellEconometricEditorSelector,
    },
    {
      headerName: "Alternative Spec #5",
      field: "5",
      flex: 1,
      cellEditorSelector: lastRowCellEconometricEditorSelector,
    },
  ];

  const transposeEstimate = (label, field) => {
    return new TransposedEstimateRow(
      Object.assign(
        { label: label },
        ...claim.estimates.map((e, index) => {
          return { [index]: e.get(field) };
        })
      )
    );
  };

  const estimateRows = List(
    ESTIMATE_FIELDS.map((ef) => transposeEstimate(ef.label, ef.field))
  );

  const INDEX_TO_COLUMN_NAME_MAP = {
    0: "preferred_specification",
    1: "alternative_spec_1",
    2: "alternative_spec_2",
    3: "alternative_spec_3",
    4: "alternative_spec_4",
    5: "alternative_spec_5",
    label: "label",
  };

  // This should be the reverse of INDEX_TO_COLUMN_NAME_MAP without label
  const COLUMN_NAME_TO_INDEX_MAP = {
    preferred_specification: 0,
    alternative_spec_1: 1,
    alternative_spec_2: 2,
    alternative_spec_3: 3,
    alternative_spec_4: 4,
    alternative_spec_5: 5,
  };

  const estimateColumnsForCSV = estimateColumns.map(
    (ec) => INDEX_TO_COLUMN_NAME_MAP[ec.field]
  );

  // This is the equivalent of transform_keys in ruby, rename object keys according to
  // INDEX_TO_COLUMN_NAME_MAP
  const estimateRowsForCSV = estimateRows.toJS().map((row) =>
    Object.keys(row).reduce((transformedRow, key) => {
      transformedRow[INDEX_TO_COLUMN_NAME_MAP[key]] = row[key];
      return transformedRow;
    }, {})
  );

  // TODO: Although it doesn't strictly matter, we should warn the user not to change the number of rows or columns in the template
  // It doesn't matter because we filter only for the 6 rows and have that hardcoded as well.
  const uploadEstimateRows = (csvRows) => {
    let estimateRows = [{}, {}, {}, {}, {}, {}]; // hardcoded to 6 rows for preferred spec and 5 alternative specs

    // Because uploaded data is transposed, need to go column by column to populate estimateRows
    csvRows.forEach((row) => {
      const field = ESTIMATE_LABEL_TO_FIELD[row.data.label];
      const row_data_keys = Object.keys(row.data).filter((key) =>
        COLUMN_NAME_TO_INDEX_MAP.hasOwnProperty(key)
      );
      for (let i = 0; i < row_data_keys.length; i++) {
        const key = row_data_keys[i];
        const index = COLUMN_NAME_TO_INDEX_MAP[key];
        estimateRows[index][field] = row.data[key];
      }
    });

    // convert to immutable for updating state in redux
    const immutableEstimateRows = List(
      estimateRows.map((row) => new Estimate(row))
    );
    updateClaim(claim.set("estimates", immutableEstimateRows));
  };

  const handleOnError = (err) => {
    alert(
      `Error occurred while uploading CSV: ${err}.\nPlease download template and try again.`
    );
  };

  const updateTransposedEstimateRowForClaim = (claimIndex) => (payload) => {
    updateTransposedEstimateRow({ claimIndex, ...payload });
  };

  return (
    <Box mb={4}>
      <div className={classes.claim}>
        <Box display="flex" alignItems="center">
          <Typography variant="h4">
            <span>Claim {index + 1}</span>
          </Typography>
        </Box>
        <div className={classes.section}>
          <QuestionWithTextField
            readOnly={readOnly}
            classes={classes}
            label="Summary"
            multiline
            fullWidth
            variant="outlined"
            value={claim.claimSummary}
            onChange={onChange("claimSummary")}
            maxLength={300}
          >
            Provide a one-sentence summary of the claim you will be assessing.
            <p>
              <small>
                Structure your summary as follows: "The paper tested the effect
                of X on Y for population P, using method M. The main results
                show an effect of magnitude E (specify units and standard
                errors)" or "The paper estimated the value of Y (estimated or
                predicted) for population P under dimensions X using method M.
                The main results presented an estimate of of magnitude E
                (specify units and standard errors)". Make sure to use the same
                units of measurement for all of the scientific claims that you
                will analyze as part of the entire exercise.
              </small>
            </p>
          </QuestionWithTextField>
          <QuestionWithTextField
            readOnly={readOnly}
            classes={classes}
            label="Short title of the claim"
            fullWidth
            variant="outlined"
            value={claim.shortDescription}
            placeholder={"e.g. effect on wages"}
            onChange={onChange("shortDescription")}
          >
            Provide your own short title for the claim.
          </QuestionWithTextField>
          <QuestionWithTextField
            readOnly={readOnly}
            classes={classes}
            multiline
            fullWidth
            variant="outlined"
            value={claim.focusedPopulation}
            placeholder="e.g. low income households in Oregon below the poverty line, that apply for and make use of a specific benefit"
            onChange={onChange("focusedPopulation")}
          >
            For this claim: describe the population for which the estimates
            apply.
          </QuestionWithTextField>
          <QuestionWithRadioGroup
            classes={classes}
            readOnly={readOnly}
            value={claim.identifiedPreferredSpecification}
            onChange={onChange("identifiedPreferredSpecification")}
            valuesAndLabels={[
              {
                value: "yes",
                label: "Yes",
              },
              {
                value: "no",
                label: "No",
              },
              {
                value: "not sure",
                label: "I'm not sure",
              },
            ]}
          >
            Did the author(s) identify a preferred analytical specification,
            i.e., a specific regression model estimated for this claim you will
            be assessing?
          </QuestionWithRadioGroup>
          <Typography
            variant="body1"
            component="div"
            className={classes.question}
            gutterBottom
          >
            In addition to the preferred specification, choose up to five
            additional specifications of the estimate for this claim you will be
            assessing and report them below. If there is no clear preferred
            specification by the authors, then choose one yourself.
            <p>
              <b>
                For the <em>Name</em> field, you must use the exact name from
                the paper. This name is significant for the assessment stage.
                <br />
                Do not include the caption e.g. if the full label is "Table 2 —
                Railroads and Trade Costs: Step 1", only use the name "Table 2".
              </b>
            </p>
            <p>
              <b>
                For econometric method below, IV=instrumental variables,
                DD=difference in differences, PSM=propensity score matching,
                RCT=randomized controlled trial, RDD=regression discontinuity
                design, OLS=ordinary least squares.
              </b>
            </p>
          </Typography>
          <SaveMessage>
            When editing the .csv file, do not add additional rows or change the
            variable names in the first column.
          </SaveMessage>
          {!readOnly && (
            <>
              <CSVUploadButton
                onDrop={uploadEstimateRows}
                onError={handleOnError}
              />
            </>
          )}
          <CSVDownloadButton
            data={estimateRowsForCSV}
            filename="claim_estimates"
            columns={estimateColumnsForCSV}
          />
          <DataGrid
            readOnly={readOnly}
            rows={estimateRows}
            columns={estimateColumns}
            updateRowAtIndex={updateTransposedEstimateRowForClaim(index)}
          />
          <QuestionWithSlider
            classes={classes}
            readOnly={readOnly}
            readOnlyLabel={
              "on a scale of 1 being the lowest confidence and 5 being the highest"
            }
            value={claim.econometricCategorizationConfidence}
            onChange={(e: any, value: number) => {
              updateClaim(
                claim.merge({ econometricCategorizationConfidence: value })
              );
            }}
            valuesAndLabels={[
              {
                value: 1,
                label: "1",
              },
              {
                value: 2,
                label: "2",
              },
              {
                value: 3,
                label: "3",
              },
              {
                value: 4,
                label: "4",
              },
              {
                value: 5,
                label: "5",
              },
            ]}
          >
            How confident are you in your categorization of the primary
            econometric method above?
          </QuestionWithSlider>
        </div>
        {!readOnly && (
          <Box mt={4} display="flex" flexDirection="row-reverse">
            <Button
              variant="contained"
              color="primary"
              onClick={nextClaim}
              disabled={disableNextClaim}
            >
              {disableNextClaim ? "This is the last claim" : "Next claim"}
            </Button>
          </Box>
        )}
      </div>
    </Box>
  );
}

function OutlineClaims({
  reproduction,
  updateFields,
  updateTransposedEstimateRow,
  readOnly,
}: {
  reproduction: Reproduction;
  updateFields: Function;
  updateTransposedEstimateRow: Function;
  readOnly: boolean;
}) {
  const updateClaim = (index: number) => (value: any) => {
    updateFields({
      claims: reproduction.claims.set(index, value),
    });
  };

  const classes = useStyles("3")();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const menuOptions = [
    "None selected",
    ...reproduction.claims.map(
      (claim, index) => `Claim ${index + 1}: ${claim.shortDescription}`
    ),
  ];

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const editClaim = (claim: Claim, index: number) => (
    <EditClaim
      readOnly={readOnly}
      key={index}
      claim={claim}
      index={index}
      updateClaim={updateClaim(index)}
      updateTransposedEstimateRow={updateTransposedEstimateRow}
      // selectedIndex and index are off by 1 to accommodate default menu option
      nextClaim={() => {
        setSelectedIndex(index + 2);
        window.scrollTo({ top: 0 });
      }}
      disableNextClaim={index + 1 === reproduction.claims.size}
    />
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Outline claims
      </Typography>
      <Typography gutterBottom>
        These are the claims you will assess as part of this reproduction
        attempt. The number of claims here depend on your answers to{" "}
        <strong>questions 1.10 and 1.12</strong>.
      </Typography>
      <div className={classes.section}>
        {reproduction.claims.isEmpty() ? (
          <Typography className={classes.noClaims} variant="body1" gutterBottom>
            This reproduction attempt doesn't have any claims associated with it
            yet. Answer <strong>questions 1.10 and 1.12</strong> to set the
            number of claims you will assess here.
          </Typography>
        ) : readOnly ? (
          reproduction.claims.map((claim, index) => {
            return (
              <Box key={index} mb={2}>
                <Paper>
                  <CardContent>{editClaim(claim, index)}</CardContent>
                </Paper>
              </Box>
            );
          })
        ) : (
          <>
            <FormControl variant="filled" className={classes.select}>
              <InputLabel id="select-claim-label">Select Claim:</InputLabel>
              <Select
                id="select-claim"
                className={classes.select}
                value={menuOptions[selectedIndex]}
              >
                {menuOptions.map((option, index) => (
                  <MenuItem
                    key={option}
                    value={option}
                    disabled={index === 0}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedIndex > 0 && (
              <Paper>
                <CardContent>
                  {editClaim(
                    reproduction.claims.get(selectedIndex - 1),
                    selectedIndex - 1
                  )}
                </CardContent>
              </Paper>
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({
  editReproduction: { reproduction },
}: {
  editReproduction: EditReproduction;
}) => {
  return { reproduction };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateFields: (fields: any) => dispatch(updateFields(fields)),
    updateTransposedEstimateRow: (fields: any) =>
      dispatch(updateTransposedEstimateRow(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlineClaims);
