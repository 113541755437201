import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saveWarning: {
      color: "#981b1e",
      fontWeight: "bold",
      paddingTop: "15px",
      paddingBottom: "15px",
    },
  })
);

function SaveMessage({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <Typography className={classes.saveWarning}>
      Note that the data inputted doesn't save automatically. Please remember to
      save your work often or download the template .csv files, populate them
      locally on your computer, and then upload them accordingly. {children}
    </Typography>
  );
}
export default SaveMessage;
