import React, { useEffect } from "react";
import classNames from "classnames";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import logo from "./ssrp_logo.svg";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: `flex`,
    border: `0`,
    borderRadius: `3px`,
    padding: `0.625rem 0`,
    transition: `all 150ms ease 0s`,
    alignItems: `center`,
    flexFlow: `row nowrap`,
    justifyContent: `flex-start`,
    position: `relative`,
    zIndex: `unset`,
    backgroundColor: "transparent",
  },
  absolute: {
    position: `absolute`,
    zIndex: theme.zIndex.appBar,
  },
  fixed: {
    position: `fixed`,
    zIndex: theme.zIndex.appBar,
  },
  container: {
    minHeight: `20px`,
    flex: `1`,
    alignItems: `center`,
    justifyContent: `space-between`,
    display: `flex`,
    flexWrap: `nowrap`,
    paddingLeft: "46px",
    paddingRight: "62px",
  },
  appResponsive: {
    margin: `20px 10px`,
  },
  logoLink: {
    maxWidth: `130px`,
    flex: `1 1 auto`,
  },
  logo: {
    objectFit: "contain",
  },
}));

export default function AppBar({
  changeColorOnScroll,
  color,
  rightLinks,
  title,
  fixed,
  absolute,
}) {
  const classes = useStyles();
  useEffect(() => {
    if (changeColorOnScroll) {
      function headerColorChange() {
        const windowsScrollTop = window.pageYOffset;

        if (windowsScrollTop > changeColorOnScroll.height) {
          document.body
            .getElementsByTagName(`header`)[0]
            .classList.remove(classes[color]);
          document.body
            .getElementsByTagName(`header`)[0]
            .classList.add(classes[changeColorOnScroll.color]);
        } else {
          document.body
            .getElementsByTagName(`header`)[0]
            .classList.add(classes[color]);
          document.body
            .getElementsByTagName(`header`)[0]
            .classList.remove(classes[changeColorOnScroll.color]);
        }
      }

      window.addEventListener(`scroll`, headerColorChange);

      return function cleanup() {
        window.removeEventListener(`scroll`, headerColorChange);
      };
    }
  }, [changeColorOnScroll, classes, color]);

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  return (
    <MuiAppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Link className={classes.logoLink} href="/">
          <CardMedia className={classes.logo} component="img" src={logo} />
        </Link>
        <Box flex="1" />
        {rightLinks}
      </Toolbar>
    </MuiAppBar>
  );
}
