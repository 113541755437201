import React from "react";
import PapaParse from "papaparse";
import { Button } from "gatsby-material-ui-components";
import { CloudDownload } from "@material-ui/icons";

function CSVDownloadButton({
  data,
  filename,
  columns,
}: {
  data: any;
  filename: string;
  columns: any; // the column names in sorted order
}) {
  const downloadTableAsCSV = () => {
    // This function is heavily inspired by
    // https://github.com/Bunlong/react-papaparse/blob/master/src/CSVDownloader.tsx

    const bomCode = "\ufeff";

    let csvContent = PapaParse.unparse(data, {
      columns: columns,
    });
    const encodedDataUrl = encodeURI(
      `data:text/csv;charset=utf8,${bomCode}${csvContent}`
    );

    const link = document.createElement("a");
    link.setAttribute("href", encodedDataUrl);
    link.setAttribute("download", `${filename}.csv`);
    link.click();
    link.remove();
  };

  return (
    <Button
      startIcon={<CloudDownload />}
      variant={"outlined"}
      color={"secondary"}
      onClick={downloadTableAsCSV}
    >
      Download table as CSV
    </Button>
  );
}

export default CSVDownloadButton;
