import { List } from "immutable";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".ag-header-cell-label .ag-header-cell-text": {
      whiteSpace: "normal",
    },
    ".ag-row-hover": {
      /* putting in !important so it overrides the theme's styling as it hovers the row also */
      backgroundColor: "#dfdfff !important",
    },
    ".ag-column-hover": {
      backgroundColor: "#dfffdf",
    },
  },
}));

class DeleteButtonRenderer extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props.node.id);
  }
  render() {
    return (
      <button onClick={this.btnClickedHandler}>
        <Delete />
      </button>
    );
  }
}

class CheckboxRenderer extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }
  checkedHandler(e) {
    this.props.checked(this.props.data, e.target.checked, this.props.node.id);
  }
  render() {
    return (
      <input
        type="checkbox"
        onClick={this.checkedHandler}
        checked={
          this.props.value !== "" &&
          this.props.value !== false &&
          this.props.value !== "false"
        }
      />
    );
  }
}

export function DataGrid({
  readOnly,
  rows,
  columns,
  updateRowAtIndex,
  deleteRowAtIndex,
}: {
  readOnly?: boolean;
  rows: List;
  columns: List;
  updateRowAtIndex: Function;
  deleteRowAtIndex?: Function;
}) {
  useStyles(); // set global ag grid styles

  const valueSetter = ({ colDef, data, newValue, node }) => {
    let trimmedNewValue = newValue?.trim();
    let updatedRow = data.set(colDef.field, trimmedNewValue);
    updateRowAtIndex({
      rowIndex: node.id,
      updatedRow,
      field: colDef.field,
      newValue: trimmedNewValue,
    });
    return true;
  };

  if (deleteRowAtIndex && !readOnly) {
    columns = columns.concat({
      headerName: "Delete",
      flex: 1,
      maxWidth: 85,
      editable: false,
      cellRenderer: "deleteButtonRenderer",
      cellRendererParams: {
        clicked: function (rowIndex) {
          deleteRowAtIndex({ rowIndex });
        },
      },
    });
  }

  return (
    <div className="ag-theme-balham" style={{ width: "100%" }}>
      <AgGridReact
        defaultColDef={{
          editable: !readOnly,
          resizable: true,
          wrapText: true,
          valueSetter: valueSetter,
          autoHeight: true,
        }}
        stopEditingWhenGridLosesFocus={true}
        singleClickEdit={!readOnly && true}
        rowData={rows}
        domLayout={"autoHeight"}
        frameworkComponents={{
          deleteButtonRenderer: DeleteButtonRenderer,
          checkboxRenderer: CheckboxRenderer,
        }}
      >
        {columns.map((columnProps, index) => (
          <AgGridColumn key={index} {...columnProps} />
        ))}
      </AgGridReact>
      <Box mb={8}></Box>
    </div>
  );
}
