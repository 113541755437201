import { handleActions } from "redux-actions";
import { List, Record, isImmutable, setIn } from "immutable";
import {
  deleteAnalyticDataRow,
  deleteCodeScriptRow,
  deleteDataSourceRow,
  fetchAssessment,
  fetchAssessmentFailure,
  fetchAssessmentSuccess,
  saveAssessmentFields,
  saveAssessmentFieldsError,
  saveAssessmentFieldsSuccess,
  updateAnalyticDataRow,
  updateAssessmentFields,
  updateCodeScriptRow,
  updateDataSourceRow,
} from "../actions/assessment.actions";
import { camelizeKeys } from "humps";

export class DataSourceRow extends Record({
  id: null,
  dataFiles: "",
  dataSource: "",
  page: "",
  location: "",
  notes: "",
  provided: "",
  cited: "",
}) {}

export class AnalyticDataRow extends Record({
  id: null,
  analyticData: "",
  location: "",
  description: "",
}) {}

export class CodeScriptRow extends Record({
  id: null,
  fileName: "",
  location: "",
  inputs: "",
  outputs: "",
  description: "",
  primaryType: "",
}) {}

export class DisplayItem extends Record({
  id: null,
  name: "",
  containsEstimates: "false",
  willAssessAllEstimates: "",
  prespecifiedEstimates: "", // This value is from the backend and read-only on frontend
  description: "",
  reproductionTreeInDiagram: "",
  reproductionTreeIncludeDataSourceFiles: "",
  reproductionTreeFragmentedButShouldBeConnected: "",
  reproductionTreeAmended: "",
  diagramCompleteness: "",
  ableToGenerateCandidateTree: "",
  allNecessaryFiles: "",
  allNecessaryFilesInBetweenExplanation: "",
  ableToExecuteAllSteps: "",
  unableToExecuteAllStepsReasons: List(),
  unableToExecuteAllStepsRequiredSoftwareVersion: "",
  unableToExecuteAllStepsOther: "",
  numMinutesInstallingAdditionalPackages: "",
  numMinutesChangingDirectoryPaths: "",
  numMinutesOtherImprovements: "",
  ableToExecuteAfterMinorCorrections: "",
  isOutputSame: "",
  isOutputSimilarExplanation: "",
  isOutputDifferentExplanation: "",
  proprietaryConfidentialDataRequired: "",
  proprietaryConfidentialDataOther: "",
  reproducibilityScore: "",
}) {}

export class Assessment extends Record({
  isAuthor: "",
  workflowStage: "",
  shareableLink: true,
  numDisplayItemsTotal: 0,
  numDisplayItemsWillAssess: 0,
  minDisplayItems: 0,
  masterFileExists: "",
  masterFileOneClick: "",
  masterFileNoOneClickReasons: List(),
  masterFileNecessarySoftware: "",
  masterFileAdditionalLibraries: "",
  masterFileOtherErrors: "",
  numMinutesInstallingAdditionalPackages: "",
  numMinutesChangingDirectoryPaths: "",
  numMinutesOtherImprovements: "",
  masterFileRunAfterCorrections: "",
  reproductionPackageTools: List(),
  reproductionPackageToolsOther: "",
  dataSourceRows: List.of(new DataSourceRow()),
  analyticDataRows: List.of(new AnalyticDataRow()),
  codeScriptRows: List.of(new CodeScriptRow()),
  displayItems: List(),
}) {}

export class EditAssessment extends Record({
  assessment: new Assessment(),
  saving: false,
  loading: false,
  notFound: false,
}) {}

function mergeAssessmentPayload(state: EditAssessment, payload: any) {
  const camelizedPayload = camelizeKeys(payload);
  camelizedPayload.masterFileNoOneClickReasons = new List(
    payload.master_file_no_one_click_reasons
  );
  camelizedPayload.reproductionPackageTools = new List(
    payload.reproduction_package_tools
  );
  camelizedPayload.dataSourceRows =
    payload?.data_source_rows?.length > 0
      ? new List(
          camelizedPayload.dataSourceRows.map((row) => new DataSourceRow(row))
        )
      : List.of(new DataSourceRow());

  camelizedPayload.analyticDataRows =
    payload?.analytic_data_rows?.length > 0
      ? new List(
          camelizedPayload.analyticDataRows.map(
            (row) => new AnalyticDataRow(row)
          )
        )
      : List.of(new AnalyticDataRow());

  camelizedPayload.codeScriptRows =
    payload?.code_script_rows?.length > 0
      ? new List(
          camelizedPayload.codeScriptRows.map((row) => new CodeScriptRow(row))
        )
      : List.of(new CodeScriptRow());

  camelizedPayload.displayItems =
    payload?.display_items?.length > 0
      ? new List(
          camelizedPayload.displayItems.map((di) => {
            di.unableToExecuteAllStepsReasons = new List(
              di.unableToExecuteAllStepsReasons
            );
            return new DisplayItem(di);
          })
        )
      : List();

  return state
    .set("assessment", state.assessment.merge(camelizedPayload))
    .set("loading", false);
}

const editAssessment = handleActions(
  {
    [updateAssessmentFields.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      if (payload.numDisplayItemsWillAssess) {
        let displayItemsList = state.assessment.displayItems;
        if (payload.numDisplayItemsWillAssess > displayItemsList.size) {
          const numDisplayItemsToAdd =
            payload.numDisplayItemsWillAssess - displayItemsList.size;
          for (let i = 0; i < numDisplayItemsToAdd; i++) {
            displayItemsList = displayItemsList.push(new DisplayItem());
          }
        } else if (payload.numDisplayItemsWillAssess < displayItemsList.size) {
          const numDisplayItemsToRemove =
            displayItemsList.size - payload.numDisplayItemsWillAssess;
          for (let i = 0; i < numDisplayItemsToRemove; i++) {
            displayItemsList = displayItemsList.pop();
          }
        }
        if (isImmutable(payload)) {
          payload.set("displayItems", displayItemsList);
        } else {
          payload.displayItems = displayItemsList;
        }
      }
      return state.set("assessment", state.assessment.merge(payload));
    },
    [updateDataSourceRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return state.setIn(
        ["assessment", "dataSourceRows"],
        state.assessment.dataSourceRows.set(
          payload.rowIndex,
          payload.updatedRow
        )
      );
    },
    [deleteDataSourceRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return setIn(
        state,
        ["assessment", "dataSourceRows"],
        state.assessment.dataSourceRows.delete(payload.rowIndex)
      );
    },
    [updateAnalyticDataRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return state.setIn(
        ["assessment", "analyticDataRows"],
        state.assessment.analyticDataRows.set(
          payload.rowIndex,
          payload.updatedRow
        )
      );
    },
    [deleteAnalyticDataRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return setIn(
        state,
        ["assessment", "analyticDataRows"],
        state.assessment.analyticDataRows.delete(payload.rowIndex)
      );
    },
    [updateCodeScriptRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return state.setIn(
        ["assessment", "codeScriptRows"],
        state.assessment.codeScriptRows.set(
          payload.rowIndex,
          payload.updatedRow
        )
      );
    },
    [deleteCodeScriptRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return setIn(
        state,
        ["assessment", "codeScriptRows"],
        state.assessment.codeScriptRows.delete(payload.rowIndex)
      );
    },
    [fetchAssessment.toString()]: (state: EditAssessment) => {
      return state.set("loading", true);
    },
    [fetchAssessmentSuccess.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return mergeAssessmentPayload(state, payload);
    },
    [fetchAssessmentFailure.toString()]: (state: EditAssessment) => {
      return state.set("loading", false).set("notFound", true);
    },
    [saveAssessmentFields.toString()]: (state: EditAssessment) => {
      return state.set("saving", true);
    },
    [saveAssessmentFieldsSuccess.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return mergeAssessmentPayload(state.set("saving", false), payload);
    },
    [saveAssessmentFieldsError.toString()]: (state: EditAssessment) => {
      return state.set("saving", false);
    },
  },
  new EditAssessment()
);

export default editAssessment;
