import { handleActions } from "redux-actions";
import { List, Record } from "immutable";
import { camelizeKeys } from "humps";
import { Reproduction, Paper } from "./edit-reproduction";
import { format } from "date-fns";
import {
  fetchAdminReproductions,
  fetchAdminReproductionsFailure,
  fetchAdminReproductionsSuccess,
} from "../actions/admin-reproductions.actions";

export class AdminReproductions extends Record({
  reproductions: List(),
  refreshing: false,
  notFound: false,
}) {}

const adminReproductions = handleActions(
  {
    [fetchAdminReproductions.toString()]: (
      state: AdminReproductions,
      { payload }: any
    ) => {
      return state.set("refreshing", true);
    },
    [fetchAdminReproductionsFailure.toString()]: (
      state: AdminReproductions,
      { payload }: any
    ) => {
      return state.set("refreshing", false).set("notFound", true);
    },
    [fetchAdminReproductionsSuccess.toString()]: (
      state: AdminReproductions,
      { payload: { reproductions } }: any
    ) => {
      return state.merge({
        refreshing: false,
        reproductions: List(
          reproductions?.map(
            (reproduction: { paper_type: any; id: number; paper: object }) => {
              return new Reproduction({
                id: reproduction.id,
                paperType: reproduction.paper_type,
                workflowStage: reproduction.workflow_stage,
                currentStage: reproduction.current_stage,
                createdAt: format(
                  new Date(reproduction.created_at),
                  "MMMM dd yyyy"
                ),
                candidateAbandonedAt: format(
                  new Date(reproduction.candidate_abandoned_at),
                  "MMMM dd yyyy"
                ),
                submittedAt: format(
                  new Date(reproduction.submitted_at),
                  "MMMM dd yyyy"
                ),
                authors: reproduction.authors,
                paper: new Paper(camelizeKeys(reproduction.paper)),
              });
            }
          )
        ),
      });
    },
  },
  new AdminReproductions()
);

export default adminReproductions;
