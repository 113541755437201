import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import ExternalLink from "../external-link";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: `flex`,
    border: `0`,
    borderRadius: `3px`,
    padding: `0.625rem 0`,
    transition: `all 150ms ease 0s`,
    alignItems: `center`,
    flexFlow: `row nowrap`,
    justifyContent: `center`,
    position: `relative`,
    zIndex: `unset`,
    backgroundColor: `#004775`,
    color: `#fff`,
    fontSize: "1rem",
  },
}));

export default function SurveyBar({ changeColorOnScroll, color, fixed }) {
  const classes = useStyles();
  return (
    <MuiAppBar className={classes.appBar}>
      <ExternalLink href="https://forms.gle/nbjXrD3Bz7zQDp4T9">
        <span style={{ color: `#fff` }}>
          Tell us about your experience with the SSRP in this 5-minute survey.
        </span>
      </ExternalLink>
    </MuiAppBar>
  );
}
