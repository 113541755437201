import keymirror from "./keymirror";

export const EditReproductionConstants = keymirror(
  {
    UPDATE_FIELDS: null,
    SAVE_FIELDS: null,
    SAVE_FIELDS_SUCCESS: null,
    SAVE_FIELDS_ERROR: null,
  },
  "edit-reproduction"
);
