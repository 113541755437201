import { createAction } from "redux-actions";

export const fetchAssessment = createAction("fetchAssessment");

export const fetchAssessmentSuccess = createAction("fetchAssessmentSuccess");
export const fetchAssessmentFailure = createAction("fetchAssessmentFailure");

export const saveAssessmentFields = createAction("saveAssessmentFields");

export const saveAssessmentFieldsSuccess = createAction(
  "saveAssessmentFieldsSuccess"
);

export const saveAssessmentFieldsError = createAction(
  "saveAssessmentFieldsError"
);

export const updateDataSourceRow = createAction("updateDataSourceRow");
export const deleteDataSourceRow = createAction("deleteDataSourceRow");

export const updateAnalyticDataRow = createAction("updateAnalyticDataRow");
export const deleteAnalyticDataRow = createAction("deleteAnalyticDataRow");

export const updateCodeScriptRow = createAction("updateCodeScriptRow");
export const deleteCodeScriptRow = createAction("deleteCodeScriptRow");

export const updateAssessmentFields = createAction("updateAssessmentFields");
