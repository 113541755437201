import { createStore as reduxCreateStore } from "redux";
import { applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";

import reducers from "./reducers";

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  let store = reduxCreateStore(
    reducers,
    compose(
      applyMiddleware(sagaMiddleware)
      // Currently this will work in all environments, if we want to restrict it
      // in production or turn it off completely see
      // https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production
      //(window as any).__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
  sagaMiddleware.run(rootSaga);
  return store;
};

export default createStore;
