import { createAction } from "redux-actions";

export const fetchImprovement = createAction("fetchImprovement");

export const fetchImprovementSuccess = createAction("fetchImprovementSuccess");
export const fetchImprovementFailure = createAction("fetchImprovementFailure");

export const saveImprovementFields = createAction("saveImprovementFields");

export const saveImprovementFieldsSuccess = createAction(
  "saveImprovementFieldsSuccess"
);

export const saveImprovementFieldsError = createAction(
  "saveImprovementFieldsError"
);

export const updateImprovementFields = createAction("updateImprovementFields");
