import { Theme } from "@material-ui/core/styles";

export const questionStyles = (section: number, theme: Theme) => {
  return {
    note: {
      marginTop: theme.spacing(4),
    },
    question: {
      marginTop: theme.spacing(4),
      "&:before": {
        counterIncrement: "question",
        fontWeight: "bold",
        content: `"${section}." counter(question) "."`,
        marginRight: theme.spacing(1),
      },
    },
    typographyDisabled: {
      color: 'rgb(0, 0, 0, 0.26)'
    },
    section: {
      counterReset: "question",
    },
  };
};
