import { handleActions } from "redux-actions";
import { List, Record } from "immutable";
import {
  deleteReproduction,
  deleteReproductionSuccess,
  fetchMyReproductions,
  fetchMyReproductionsFailure,
  fetchMyReproductionsSuccess,
} from "../actions/my-reproductions.actions";
import { camelizeKeys } from "humps";
import { Reproduction, Paper } from "./edit-reproduction";
import { format } from "date-fns";

export class MyReproductions extends Record({
  reproductions: List(),
  refreshing: false,
  notFound: false,
  signedOut: false,
}) {}

const myReproductions = handleActions(
  {
    [deleteReproductionSuccess.toString()]: (
      state: MyReproductions,
      { payload: { id } }: any
    ) => {
      return state.set(
        "reproductions",
        state.reproductions.filter((r) => r.id !== id)
      );
    },
    [fetchMyReproductions.toString()]: (
      state: MyReproductions,
      { payload }: any
    ) => {
      return state.set("refreshing", true);
    },
    [fetchMyReproductionsFailure.toString()]: (
      state: MyReproductions,
      { payload }: any
    ) => {
      return state.set("notFound", true);
    },
    [fetchMyReproductionsSuccess.toString()]: (
      state: MyReproductions,
      { payload: { reproductions } }: any
    ) => {
      return state.merge({
        refreshing: false,
        reproductions: List(
          reproductions?.map(
            (reproduction: { paper_type: any; id: number; paper: object }) => {
              return new Reproduction({
                id: reproduction.id,
                projectNickname: reproduction.project_nickname,
                paperType: reproduction.paper_type,
                reproductionType: reproduction.reproduction_type,
                paperLevelReproducibilityScore:
                  reproduction.paper_level_reproducibility_score,
                workflowStage: reproduction.workflow_stage,
                currentStage: reproduction.current_stage,
                numClaimsAssessed: reproduction.num_claims_assessed,
                numDisplayItemsAssessed:
                  reproduction.num_display_items_assessed,
                createdAt: format(
                  new Date(reproduction.created_at),
                  "MMMM dd yyyy"
                ),
                candidateAbandonedAt: format(
                  new Date(reproduction.candidate_abandoned_at),
                  "MMMM dd yyyy"
                ),
                submittedAt: format(
                  new Date(reproduction.submitted_at),
                  "MMMM dd yyyy"
                ),
                paper: new Paper(camelizeKeys(reproduction.paper)),
              });
            }
          )
        ),
      });
    },
  },
  new MyReproductions()
);

export default myReproductions;
