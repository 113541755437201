import { handleActions } from "redux-actions";
import { Record } from "immutable";
import { updateUser } from "../actions/user.actions";

export class User extends Record({
  family_name: "",
  given_name: "",
  gender: "",
  ethnicities: [],
  email: "",
  id: "",
  unconfirmed_orcid: false,
  verified: false, // rename this, boolean stands for whether session cookie has been checked against backend
}) {}

const user = handleActions(
  {
    [updateUser.toString()]: (state: User, { payload }: any) => {
      return state.merge({
        verified: true,
        ...payload,
      });
    },
  },
  new User()
);

export default user;
