import { createAction, handleActions } from "redux-actions";
import { List, Record } from "immutable";
import {
  updateFields,
  saveFields,
  saveFieldsSuccess,
  saveFieldsError,
  fetchReproductionSuccess,
  fetchReproductionFailure,
  fetchReproduction,
  declarePaper,
  updateTransposedEstimateRow,
} from "../actions/edit-reproductions.actions";
import { camelizeKeys } from "humps";
import { ESTIMATE_FIELDS } from "../reproduction-pages/edit/scoping/outline-claims";

export class ReproductionPackage extends Record({
  id: null,
  name: "",
  url: "",
  stage: "",
  contentType: "",
}) {}

export class Estimate extends Record({
  id: null,
  estimate: "",
  standardError: "",
  units: "",
  pValue: "",
  confidenceInterval: "",
  otherStatistic: "",
  name: "",
  page: "",
  column: "",
  row: "",
  inlineParagraph: "",
  econometricMethod: "",
  otherEconometricMethod: "",
}) {}

export class Claim extends Record({
  id: null,
  shortDescription: "",
  claimSummary: "",
  focusedPopulation: "",
  identifiedPreferredSpecification: "",
  econometricCategorizationConfidence: "",
  estimates: List.of(
    new Estimate(),
    new Estimate(),
    new Estimate(),
    new Estimate(),
    new Estimate(),
    new Estimate()
  ),
}) {}

export class Paper extends Record({
  title: "",
  publicationName: "",
  publicationYear: "",
  doi: "",
  url: "",
  authors: "",
}) {}

export class Outputs extends Record({
  numTablesBody: "",
  numFiguresBody: "",
  numInlineResultsBody: "",
  attemptAllInlineResultsBody: "",
  numTablesAppendix: "",
  attemptAllTablesAppendix: "",
  numFiguresAppendix: "",
  attemptAllFiguresAppendix: "",
}) {}

export class Reproduction extends Record({
  id: 0,
  projectNickname: "",
  paperType: "candidate",
  candidateAbandonedAt: "",
  workflowStage: "select_paper",
  numClaimsAssessed: "",
  numDisplayItemsAssessed: "",
  currentStage: "",
  submittedAt: "",
  doi: "",
  shareableLink: true,
  authors: List(),
  isAuthor: "",
  createdAt: "",
  startDate: new Date(),
  endDate: null,
  authorsResponse: List(),
  authorsResponseOther: "",
  authorDidNotRespondAsOfDate: null,
  authorsResponseNotReadyDate: null,
  expectedTotalHours: "1",
  paper: new Paper(),
  familiarityLevel: "",
  outputs: new Outputs(),
  authorsContacted: null,
  authorsAvailable: null,
  reproductionPackageAvailable: "",
  reproductionPackageAvailablePartialReason: "",
  reproductionPackageFromScratch: null,
  reproductionType: "",
  summary: "",
  numClaims: "",
  claimType: "",
  claimTypeOtherDescription: "",
  numClaimsWillAssess: "",
  willAssessWholePaper: "",
  possibleRobustnessChecks: "",
  wholePopulation: "",
  additionalPopulation: "",
  paperLevelReproducibilityScore: "",
  originalReproductionPackages: List.of(
    new ReproductionPackage({ stage: "original", contentType: "code" })
  ),
  revisedReproductionPackages: List.of(
    new ReproductionPackage({ stage: "revised", contentType: "code" })
  ),
  claims: List(),
}) {}

export class EditReproduction extends Record({
  reproduction: new Reproduction(),
  saving: false,
  loading: false,
  notFound: false,
}) {}

function mergeReproductionPayload(state: EditReproduction, payload: any) {
  const camelizedPayload = camelizeKeys(payload);
  // Ensure correct format for reproductionPackageAvailable
  if (camelizedPayload.reproductionPackageAvailable === "true") {
    camelizedPayload.reproductionPackageAvailable = "true:full";
  } else if (camelizedPayload.reproductionPackageAvailable === "false") {
    camelizedPayload.reproductionPackageAvailable = "false:none";
  }

  camelizedPayload.authorsResponse = new List(payload.authors_response);
  camelizedPayload.paper = new Paper(camelizedPayload.paper);
  camelizedPayload.outputs = new Outputs(camelizedPayload.outputs);
  camelizedPayload.revisedReproductionPackages =
    payload?.revised_reproduction_packages?.length > 0
      ? new List(
          camelizedPayload.revisedReproductionPackages.map(
            (rp) => new ReproductionPackage(rp)
          )
        )
      : List.of(
          new ReproductionPackage({ stage: "revised", contentType: "code" })
        );
  camelizedPayload.originalReproductionPackages =
    payload?.original_reproduction_packages?.length > 0
      ? new List(
          camelizedPayload.originalReproductionPackages.map(
            (rp) => new ReproductionPackage(rp)
          )
        )
      : List.of(
          new ReproductionPackage({ stage: "original", contentType: "code" })
        );
  camelizedPayload.claims = new List(
    camelizedPayload?.claims?.map((c) => {
      c.estimates = new List(c?.estimates?.map((e) => new Estimate(e)));
      return new Claim(c);
    })
  );
  return state
    .set("reproduction", state.reproduction.merge(camelizedPayload))
    .set("loading", false);
}

const editReproduction = handleActions(
  {
    [updateFields.toString()]: (state: EditReproduction, { payload }: any) => {
      if (payload.numClaimsWillAssess) {
        let claimsList = state.reproduction.claims;
        if (payload.numClaimsWillAssess > claimsList.size) {
          const numClaimsToAdd = payload.numClaimsWillAssess - claimsList.size;
          for (let i = 0; i < numClaimsToAdd; i++) {
            claimsList = claimsList.push(new Claim());
          }
        } else if (payload.numClaimsWillAssess < claimsList.size) {
          const numClaimsToRemove =
            claimsList.size - payload.numClaimsWillAssess;
          for (let i = 0; i < numClaimsToRemove; i++) {
            claimsList = claimsList.pop();
          }
        }
        payload.claims = claimsList;
        // are we sure the above works?
        //payload.set("claims", claimsList);
      }
      return state.set("reproduction", state.reproduction.merge(payload));
    },
    [updateTransposedEstimateRow.toString()]: (
      state: EditReproduction,
      { payload: { claimIndex, rowIndex, updatedRow, field, newValue } }
    ) => {
      // field here corresponds to the DataGrid column. Because this table is transposed,
      // this is actually an index into the estimates array
      const index = parseInt(field);
      // rowIndex corresponds to the list of the field names, so use that to get the fieldName
      const fieldName = ESTIMATE_FIELDS[parseInt(rowIndex)].field;
      const claim = state.reproduction.claims.get(claimIndex);
      const estimate = claim.estimates
        .get(index)
        .merge({ [fieldName]: newValue });

      return state.setIn(
        ["reproduction", "claims"],
        state.reproduction.claims.set(
          claimIndex,
          claim.set("estimates", claim.estimates.set(index, estimate))
        )
      );
    },
    [fetchReproduction.toString()]: (state: EditReproduction) => {
      return state.set("loading", true);
    },
    [fetchReproductionSuccess.toString()]: (
      state: EditReproduction,
      { payload }: any
    ) => {
      return mergeReproductionPayload(state, payload);
    },
    [fetchReproductionFailure.toString()]: (state: EditReproduction) => {
      return state.set("loading", false).set("notFound", true);
    },
    [saveFields.toString()]: (state: EditReproduction) => {
      return state.set("saving", true);
    },
    [saveFieldsSuccess.toString()]: (
      state: EditReproduction,
      { payload }: any
    ) => {
      return mergeReproductionPayload(state.set("saving", false), payload);
    },
    [saveFieldsError.toString()]: (state: EditReproduction) => {
      return state.set("saving", false);
    },
  },
  new EditReproduction()
);

export default editReproduction;
