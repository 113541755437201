import React from "react";
import StyledLink from "./styled-link";

export default function ExternalLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <StyledLink href={href} rel="noreferrer" target="_blank">
      {children}
    </StyledLink>
  );
}
