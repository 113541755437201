import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import bitssLogo from "./bitss_logo.png";
import cegaLogo from "./cega_logo.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".footer-container": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      margin: theme.spacing(0, 0, 2, 0),
      backgroundColor: `#004775`,
      minHeight: "50px",
      width: "100%",
      padding: "20px 62px",
      display: "flex",
      flexDirection: "column",
    },
    ".footer-top-bar": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    ".footer-link": {
      color: `#fff`,
      fontSize: `1.25rem`, // same as h4
      fontWeight: "bold",
      letterSpacing: "1px",
      margin: theme.spacing(0, 2, 0, 0),
    },
    ".footer-copyright": {
      color: `#fff`,
      fontSize: `0.75rem`,
      letterSpacing: "1px",
    },
    ".footer-bitss-logo": {
      height: "60px",
    },
    ".footer-cega-logo": {
      height: "40px",
    },
  },
}));

const links = [
  { name: "Contact", link: "/contact-us" },
  { name: "Terms of Use", link: "/terms-of-use" },
  { name: "Code of Conduct", link: "/code-of-conduct" },
  { name: "Team", link: "/team" },
];

export default function Footer() {
  useStyles(); // create global styles used in this component

  return (
    <>
      <Box display="flex" flexDirection="column" className="footer-container">
        <Box className="footer-top-bar" mb={2}>
          {links.map((link) => (
            <Link
              key={link.name}
              href={link.link}
              variant="subtitle1"
              className="footer-link"
            >
              {link.name}
            </Link>
          ))}
          <Link href="https://www.bitss.org/" rel="noreferrer" target="_blank">
            <img
              src={bitssLogo}
              className="footer-bitss-logo"
              alt="BITSS Logo"
            />
          </Link>
          <Link
            href="https://cega.berkeley.edu/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={cegaLogo} className="footer-cega-logo" alt="CEGA Logo" />
          </Link>
        </Box>
        <div>
          <Typography className="footer-copyright">
            Except where otherwise noted, content on this site is Copyright ©
            2021 by the UC Regents and licensed under a Creative Commons
            Attribution-NonCommercial license (
            <Link
              href="https://creativecommons.org/licenses/by-nc/4.0/"
              style={{ color: `#0999cd` }}
            >
              CC-BY-NC 4.0
            </Link>
            ).
          </Typography>
        </div>
      </Box>
    </>
  );
}
