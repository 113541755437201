import { createAction } from "redux-actions";

export const fetchRobustness = createAction("fetchRobustness");

export const fetchRobustnessSuccess = createAction("fetchRobustnessSuccess");
export const fetchRobustnessFailure = createAction("fetchRobustnessFailure");

export const saveRobustnessFields = createAction("saveRobustnessFields");

export const saveRobustnessFieldsSuccess = createAction(
  "saveRobustnessFieldsSuccess"
);

export const saveRobustnessFieldsError = createAction(
  "saveRobustnessFieldsError"
);

export const updateRobustnessFields = createAction("updateRobustnessFields");

export const updateRobustnessMappingRow = createAction(
  "updateRobustnessMappingRow"
);
export const deleteRobustnessMappingRow = createAction(
  "deleteRobustnessMappingRow"
);
