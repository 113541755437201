import Typography from "@material-ui/core/Typography";
import React from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
  })
);

function QuestionWithRadioGroup(props: {
  name?: string;
  classes?: ClassNameMap;
  questionClass?: any;
  readOnly: boolean;
  readOnlyLabel?: string;
  children: React.ReactNode;
  value: any;
  error: any;
  helperText: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  valuesAndLabels: Array<{ label: string; value: string }>;
}) {
  const componentClasses = useStyles();

  return props.readOnly ? (
    <>
      <Typography
        variant="body1"
        component="div"
        className={props.questionClass || props.classes.question}
        gutterBottom
      >
        {props.children}
      </Typography>
      <Typography
        className={componentClasses.readOnly}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {
          props.valuesAndLabels.find(({ value }) => value === props.value)
            ?.label
        }
        {props.readOnlyLabel && " " + props.readOnlyLabel}
      </Typography>
    </>
  ) : (
    <>
      <Typography
        variant="body1"
        component="div"
        className={(props.disabled
          ? [
              props.classes.typographyDisabled,
              props.questionClass || props.classes.question,
            ]
          : [props.questionClass || props.classes.question]
        ).join(" ")}
        gutterBottom
      >
        {props.children}
      </Typography>
      <FormControl
        component="fieldset"
        error={props.error}
        disabled={props.disabled}
      >
        <RadioGroup
          aria-label={props.children}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        >
          {props.valuesAndLabels.map(({ value, label }) => (
            <Box key={value} mb={1}>
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={label}
              />
            </Box>
          ))}
        </RadioGroup>
        {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
      </FormControl>
    </>
  );
}

export default QuestionWithRadioGroup;
