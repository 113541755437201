import React from "react";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// @ts-ignore
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";

import createStore from "../../state/createStore";
import { Theme } from "@material-ui/core";
import "../../styles/global.css";
import DateFnsUtils from "@date-io/date-fns";

export default function TopLayout({
  children,
  theme,
}: {
  children: any;
  theme: Theme;
}) {
  const store = createStore();
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}
