import Typography from "@material-ui/core/Typography";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    readOnly: {
      padding: theme.spacing(1),
      border: "1px solid",
      backgroundColor: "#f1f1f1",
    },
  })
);

function QuestionWithTextField(props: {
  readOnly: boolean;
  children: React.ReactNode;
  type?: string;
  variant?: string;
  name?: string;
  label?: string;
  classes: ClassNameMap;
  questionClass?: any;
  value: null;
  InputProps?: any;
  onChange: (e: any) => void;
  placeholder?: string;
  rows?: number;
  multiline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  noQuestionNumber?: boolean;
  maxLength?: number;
}) {
  const componentClasses = useStyles();

  const questionClass = !props.noQuestionNumber
    ? props.questionClass || props.classes.question
    : "";

  return props.readOnly ? (
    <>
      <Typography
        variant="body1"
        component="div"
        className={questionClass}
        gutterBottom
      >
        {props.children}
      </Typography>
      <Typography
        className={componentClasses.readOnly}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {props.value}
      </Typography>
    </>
  ) : (
    <>
      <Typography
        variant="body1"
        component="div"
        className={(props.disabled
          ? [props.classes.typographyDisabled, questionClass]
          : [questionClass]
        ).join(" ")}
        gutterBottom
      >
        {props.children}
      </Typography>
      <TextField
        name={props.name}
        label={props.label}
        type={props.type}
        InputProps={props.InputProps}
        variant={props.variant}
        multiline={props.multiline}
        rows={props.rows}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        inputProps={{maxLength: props.maxLength}}
      />
    </>
  );
}

export default QuestionWithTextField;
