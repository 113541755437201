import {
  all,
  takeEvery,
  take,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { updateUser } from "../actions/user.actions";
import {
  fetchMyReproductionsFailure,
  fetchMyReproductionsSuccess,
  fetchMyReproductions as fetchMyReproductionsAction,
  createReproduction as createReproductionAction,
  createMinimumReproduction as createMinimumReproductionAction,
  createReproductionFailure,
  deleteReproduction as deleteReproductionAction,
  deleteReproductionFailure,
  deleteReproductionSuccess,
} from "../actions/my-reproductions.actions";
import {
  saveFields as saveFieldsAction,
  saveFieldsSuccess,
  saveFieldsError,
  fetchReproduction as fetchReproductionAction,
  fetchReproductionFailure,
  fetchReproductionSuccess,
} from "../actions/edit-reproductions.actions";
import { EditReproductionConstants } from "../constants/edit-reproduction.constants";
import {
  saveAssessmentFields,
  fetchAssessment,
  fetchAssessmentFailure,
  fetchAssessmentSuccess,
  saveAssessmentFieldsSuccess,
  saveAssessmentFieldsError,
} from "../actions/assessment.actions";
import {
  fetchImprovement,
  fetchImprovementFailure,
  fetchImprovementSuccess,
  saveImprovementFields,
  saveImprovementFieldsError,
  saveImprovementFieldsSuccess,
} from "../actions/improvement.actions";
import { navigate } from "@reach/router";
import {
  fetchRobustness,
  fetchRobustnessFailure,
  fetchRobustnessSuccess,
  saveRobustnessFields,
  saveRobustnessFieldsError,
  saveRobustnessFieldsSuccess,
} from "../actions/robustness.actions";
import {
  fetchAdminReproductions,
  fetchAdminReproductionsFailure,
  fetchAdminReproductionsSuccess,
} from "../actions/admin-reproductions.actions";

export function* rootSaga() {
  yield all([
    checkSignedIn(),
    doFetchAdminReproductions(),
    doFetchMyReproductions(),
    createReproduction(),
    createMinimumReproduction(),
    doDeleteReproduction(),
    doFetchReproduction(),
    doSave(),
    doFetchAssessment(),
    doSaveAssessment(),
    doFetchImprovement(),
    doSaveImprovement(),
    doFetchRobustness(),
    doSaveRobustness(),
  ]);
}

export function* checkSignedIn() {
  // TODO: check local storage first instead of /api/my/user
  const response = yield fetch("/api/my/user", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 401) {
    yield put(updateUser({}));
  } else {
    const json = yield response.json();
    // TODO: store user state in local storage?
    yield put(updateUser(json));
  }
}

export function* doFetchAdminReproductions() {
  yield takeEvery(
    fetchAdminReproductions.toString(),
    callFetchAdminReproductions
  );
}

export function* callFetchAdminReproductions() {
  const response = yield fetch("/api/admin/reproductions", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    yield put(fetchAdminReproductionsFailure(response));
  } else {
    const json = yield response.json();
    yield put(fetchAdminReproductionsSuccess(json));
  }
}

export function* doFetchMyReproductions() {
  yield takeEvery(fetchMyReproductionsAction.toString(), fetchMyReproductions);
}

export function* fetchMyReproductions() {
  const response = yield fetch("/api/my/reproductions", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    yield put(fetchMyReproductionsFailure(response));
  } else {
    const json = yield response.json();
    yield put(fetchMyReproductionsSuccess(json));
  }
}

function* doSave() {
  yield takeLatest(EditReproductionConstants.SAVE_FIELDS, save);
}

function* save({ payload: { nextUrl, workflowStage } }: any) {
  const editedReproduction = yield select((state) => {
    return state.editReproduction.get("reproduction").toJS();
  });

  if (workflowStage === "advance_to_assessment") {
    editedReproduction.workflowStage = workflowStage;
  } else if (workflowStage === "declare_paper") {
    editedReproduction.paperType = "declared";
  } else if (workflowStage === "abandon_candidate_paper") {
    editedReproduction.paperType = "abandoned candidate";
  } else if (workflowStage === "submit") {
    editedReproduction.workflowStage = workflowStage;
  }

  const response = yield fetch(`/api/reproductions/${editedReproduction.id}`, {
    method: "PATCH",
    body: JSON.stringify(editedReproduction),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 200) {
    const json = yield response.json();
    yield put(saveFieldsSuccess(json));
    if (nextUrl) {
      navigate(nextUrl);
    }
  } else {
    yield put(saveFieldsError());
  }
}

export function* doFetchReproduction() {
  yield takeLatest(fetchReproductionAction.toString(), fetchReproduction);
}

export function* fetchReproduction({ payload: { id, mode } }: any) {
  const url =
    mode === "published"
      ? `/api/reproductions/${id}?mode=${mode}`
      : `/api/reproductions/${id}`;
  const response = yield fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    yield put(fetchReproductionFailure(response));
  } else {
    const json = yield response.json();
    yield put(fetchReproductionSuccess(json));
  }
}

export function* createReproduction() {
  while (true) {
    yield take(createReproductionAction.toString());
    const response = yield fetch("/api/reproductions", {
      method: "POST",
      body: JSON.stringify({ reproduction_type: "full" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      yield put(createReproductionFailure(response));
    } else {
      const json = yield response.json();
      // TODO: handle create more elegantly than doing a hard location change
      // @ts-ignore
      window.location = `/reproductions/${json.id}/index`;
    }
  }
}

export function* createMinimumReproduction() {
  while (true) {
    yield take(createMinimumReproductionAction.toString());
    const response = yield fetch("/api/reproductions", {
      method: "POST",
      body: JSON.stringify({ reproduction_type: "minimum" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      yield put(createReproductionFailure(response));
    } else {
      const json = yield response.json();
      window.location = `/reproductions/${json.id}/minimum-reproduction`;
    }
  }
}

export function* doDeleteReproduction() {
  yield takeEvery(deleteReproductionAction.toString(), deleteReproduction);
}

export function* deleteReproduction({ payload: { id } }: any) {
  const response = yield fetch(`/api/reproductions/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status >= 200 && response.status < 300) {
    yield put(deleteReproductionSuccess({ id }));
  } else {
    yield put(deleteReproductionFailure(response));
  }
}

export function* doFetchAssessment() {
  yield takeLatest(fetchAssessment.toString(), callFetchAssessment);
}

export function* callFetchAssessment({ payload: { id, mode } }: any) {
  const url =
    mode === "published"
      ? `/api/assessments/${id}?mode=${mode}`
      : `/api/assessments/${id}`;
  const response = yield fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    yield put(fetchAssessmentFailure(response));
  } else {
    const json = yield response.json();
    yield put(fetchAssessmentSuccess(json));
  }
}

function* doSaveAssessment() {
  yield takeLatest(saveAssessmentFields.toString(), callSaveAssessment);
}

function* callSaveAssessment({ payload: { reproductionId, nextUrl } }: any) {
  const editedAssessment = yield select((state) => {
    return state.editAssessment.get("assessment").toJS();
  });

  const response = yield fetch(`/api/assessments/${reproductionId}`, {
    method: "PATCH",
    body: JSON.stringify(editedAssessment),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 200) {
    const json = yield response.json();
    yield put(saveAssessmentFieldsSuccess(json));
    if (nextUrl) {
      navigate(nextUrl);
    }
  } else {
    yield put(saveAssessmentFieldsError());
  }
}

export function* doFetchImprovement() {
  yield takeLatest(fetchImprovement.toString(), callFetchImprovement);
}

export function* callFetchImprovement({ payload: { id, mode } }: any) {
  const url =
    mode === "published"
      ? `/api/improvements/${id}?mode=${mode}`
      : `/api/improvements/${id}`;
  const response = yield fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    yield put(fetchImprovementFailure(response));
  } else {
    const json = yield response.json();
    yield put(fetchImprovementSuccess(json));
  }
}

function* doSaveImprovement() {
  yield takeLatest(saveImprovementFields.toString(), callSaveImprovement);
}

function* callSaveImprovement({ payload: { reproductionId, nextUrl } }: any) {
  const editedImprovement = yield select((state) => {
    return state.editImprovement.get("improvement").toJS();
  });

  const response = yield fetch(`/api/improvements/${reproductionId}`, {
    method: "PATCH",
    body: JSON.stringify(editedImprovement),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 200) {
    const json = yield response.json();
    yield put(saveImprovementFieldsSuccess(json));
    if (nextUrl) {
      navigate(nextUrl);
    }
  } else {
    yield put(saveImprovementFieldsError());
  }
}

export function* doFetchRobustness() {
  yield takeLatest(fetchRobustness.toString(), callFetchRobustness);
}

export function* callFetchRobustness({ payload: { id, mode } }: any) {
  const url =
    mode === "published"
      ? `/api/robustness/${id}?mode=${mode}`
      : `/api/robustness/${id}`;
  const response = yield fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    yield put(fetchRobustnessFailure(response));
  } else {
    const json = yield response.json();
    yield put(fetchRobustnessSuccess(json));
  }
}
function* doSaveRobustness() {
  yield takeLatest(saveRobustnessFields.toString(), callSaveRobustness);
}

function* callSaveRobustness({
  payload: { reproductionId, nextUrl, workflowStage },
}: any) {
  const editedRobustness = yield select((state) => {
    return state.editRobustness.get("robustness").toJS();
  });

  if (workflowStage === "submit") {
    editedRobustness.workflowStage = workflowStage;
  }

  const response = yield fetch(`/api/robustness/${reproductionId}`, {
    method: "PATCH",
    body: JSON.stringify(editedRobustness),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (response.status === 200) {
    const json = yield response.json();
    yield put(saveRobustnessFieldsSuccess(json));
    if (nextUrl) {
      navigate(nextUrl);
    }
  } else {
    yield put(saveRobustnessFieldsError());
  }
}
