import { handleActions } from "redux-actions";
import { List, Record, isImmutable } from "immutable";
import {
  fetchImprovement,
  fetchImprovementFailure,
  fetchImprovementSuccess,
  saveImprovementFields,
  saveImprovementFieldsError,
  saveImprovementFieldsSuccess,
  updateImprovementFields,
} from "../actions/improvement.actions";
import { camelizeKeys } from "humps";

export class DisplayItemImprovement extends Record({
  id: null,
  improvementType: "",
  description: "",
}) {}

export class ImprovedDisplayItem extends Record({
  id: null,
  name: "",
  makeImprovementsChoice: "",
  currentImprovements: List.of(new DisplayItemImprovement()),
  updateScoreChoice: "",
  updatedReproducibilityScore: "",
  futureImprovements: List(),
  originalScore: "", // from backend and should be read only
}) {}

export class PaperLevelImprovement extends Record({
  id: null,
  improvementType: "",
  description: "",
}) {}

export class Improvement extends Record({
  isAuthor: "",
  workflowStage: "",
  shareableLink: true,
  currentPaperLevelImprovements: List(),
  futurePaperLevelImprovements: List(),
  improvedDisplayItems: List(),
}) {}

export class EditImprovement extends Record({
  improvement: new Improvement(),
  saving: false,
  loading: false,
  notFound: false,
}) {}

function mergeImprovementPayload(state: EditImprovement, payload: any) {
  const camelizedPayload = camelizeKeys(payload);

  camelizedPayload.currentPaperLevelImprovements =
    payload?.current_paper_level_improvements?.length > 0
      ? new List(
          camelizedPayload.currentPaperLevelImprovements.map(
            (cpli) => new PaperLevelImprovement(cpli)
          )
        )
      : new List();

  camelizedPayload.futurePaperLevelImprovements =
    payload?.future_paper_level_improvements?.length > 0
      ? new List(
          camelizedPayload.futurePaperLevelImprovements.map(
            (fpli) => new PaperLevelImprovement(fpli)
          )
        )
      : new List();

  camelizedPayload.improvedDisplayItems =
    payload?.improved_display_items?.length > 0
      ? new List(
          camelizedPayload.improvedDisplayItems.map((idi) => {
            idi.currentImprovements =
              idi?.currentImprovements?.length > 0
                ? new List(
                    idi.currentImprovements.map(
                      (ci) => new DisplayItemImprovement(ci)
                    )
                  )
                : List.of(new DisplayItemImprovement());

            idi.futureImprovements =
              idi?.futureImprovements?.length > 0
                ? new List(
                    idi.futureImprovements.map(
                      (ci) => new DisplayItemImprovement(ci)
                    )
                  )
                : new List();
            return new ImprovedDisplayItem(idi);
          })
        )
      : new List();

  return state
    .set("improvement", state.improvement.merge(camelizedPayload))
    .set("loading", false);
}

const editImprovement = handleActions(
  {
    [updateImprovementFields.toString()]: (
      state: EditImprovement,
      { payload }: any
    ) => {
      return state.set("improvement", state.improvement.merge(payload));
    },
    [fetchImprovement.toString()]: (state: EditImprovement) => {
      return state.set("loading", true);
    },
    [fetchImprovementSuccess.toString()]: (
      state: EditImprovement,
      { payload }: any
    ) => {
      return mergeImprovementPayload(state, payload);
    },
    [fetchImprovementFailure.toString()]: (state: EditImprovement) => {
      return state.set("loading", false).set("notFound", true);
    },
    [saveImprovementFields.toString()]: (state: EditImprovement) => {
      return state.set("saving", true);
    },
    [saveImprovementFieldsSuccess.toString()]: (
      state: EditImprovement,
      { payload }: any
    ) => {
      return mergeImprovementPayload(state.set("saving", false), payload);
    },
    [saveImprovementFieldsError.toString()]: (state: EditImprovement) => {
      return state.set("saving", false);
    },
  },
  new EditImprovement()
);

export default editImprovement;
