import keymirrorOrig from "keymirror";

function keymirror(obj: any, prefix?: string) {
  const objWithKeys = keymirrorOrig(obj);

  if (prefix) {
    const p: string = prefix;
    Object.keys(objWithKeys).forEach((key) => {
      objWithKeys[key] = p + "_" + objWithKeys[key];
    });
  }

  return objWithKeys;
}

export default keymirror;
