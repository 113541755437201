import { createAction } from "redux-actions";

export const fetchAdminReproductions = createAction("fetchAdminReproductions");

export const fetchAdminReproductionsSuccess = createAction(
  "fetchAdminReproductionsSuccess"
);
export const fetchAdminReproductionsFailure = createAction(
  "fetchAdminReproductionsFailure"
);
