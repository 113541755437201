import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import ExternalLink from "../external-link";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: `flex`,
    border: `0`,
    borderRadius: `3px`,
    padding: `0.625rem 0`,
    transition: `all 150ms ease 0s`,
    alignItems: `center`,
    flexFlow: `row nowrap`,
    justifyContent: `center`,
    position: `relative`,
    zIndex: `unset`,
    backgroundColor: `#D6700A`,
    color: `#fff`,
    fontSize: "1rem",
  },
}));

export default function BetaBar({ changeColorOnScroll, color, fixed }) {
  const classes = useStyles();
  return (
    <MuiAppBar className={classes.appBar}>
      <ExternalLink href="https://github.com/BITSS/SSRP_Feedback/issues">
        <span style={{ color: `#fff` }}>
          Request a feature or report a bug.
        </span>
      </ExternalLink>
    </MuiAppBar>
  );
}
