import red from "@material-ui/core/colors/red";
import { createMuiTheme, Theme } from "@material-ui/core";

// A custom theme for this app
const theme: Theme = createMuiTheme({
  typography: {
    fontFamily: ["Karla", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      fontSize: 40,
      fontWeight: `bold`,
      color: `#B35509`,
    },
    h2: {
      fontSize: 32,
      fontWeight: `bold`,
    },
    h3: {
      fontSize: 25,
      fontWeight: `bold`,
    },
    h4: {
      fontSize: 20,
      fontWeight: `bold`,
    },
    body1: {
      fontSize: `1rem`,
    },
  },
  palette: {
    primary: {
      main: `#FF8000`,
    },
    secondary: {
      main: `#004775`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: `#fff`,
    },
  },
});

export default theme;
