import React, { useState } from "react";
import { Button } from "gatsby-material-ui-components";
import { connect } from "react-redux";
import { Menu as MenuIcon } from "@material-ui/icons";
import { IconButton, Menu, MenuItem, Box } from "@material-ui/core";
import { navigate } from "gatsby";

const ITEM_HEIGHT = 48;

const signOut = async () => {
  const res = await fetch("/users/sign_out.json", {
    method: "DELETE",
    credentials: "same-origin",
  });

  if (res.status === 204) {
    window.location = "/";
  }
};

function LongMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ marginLeft: "20px" }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/reproductions/home");
            handleClose();
          }}
        >
          My Work
        </MenuItem>

        <MenuItem
          onClick={() => {
            window.location = "/users/edit";
            handleClose();
          }}
        >
          Account
        </MenuItem>

        <MenuItem
          onClick={async () => {
            await signOut();
            handleClose();
          }}
        >
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}

function RightLinks({ user }) {
  if (user.verified && user.email) {
    return (
      <>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {user.email}

          <LongMenu />
        </Box>
      </>
    );
  } else if (user.verified && !user.email) {
    return (
      <>
        <Button variant={"contained"} color={"secondary"} href={"/sign_in"}>
          Sign in
        </Button>
      </>
    );
  } else {
    return null;
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RightLinks);
