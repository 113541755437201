import React from "react";
import { Title } from "gatsby-theme-material-ui-layout/components";

import AppBar from "./app-bar";
import RightLinks from "./right-links";
import BetaBar from "./beta-bar";
import SurveyBar from "./survey-bar";

export default function Header() {
  return (
    <>
      <BetaBar />
      <SurveyBar />
      <AppBar title={<Title />} rightLinks={<RightLinks />} />
    </>
  );
}
