import { combineReducers } from "redux";
import user from "./user";
import editReproduction from "./edit-reproduction";
import myReproductions from "./my-reproductions";
import editAssessment from "./edit-assessment";
import editImprovement from "./edit-improvement";
import editRobustness from "./edit-robustness";
import adminReproductions from "./admin-reproductions";

const reducers = combineReducers({
  user: user,
  editReproduction: editReproduction,
  adminReproductions: adminReproductions,
  myReproductions: myReproductions,
  editAssessment: editAssessment,
  editImprovement: editImprovement,
  editRobustness: editRobustness,
});

export default reducers;
