import { handleActions } from "redux-actions";
import { List, Record, isImmutable, setIn, set } from "immutable";
import { camelizeKeys } from "humps";
import {
  deleteRobustnessMappingRow,
  fetchRobustness,
  fetchRobustnessFailure,
  fetchRobustnessSuccess,
  saveRobustnessFields,
  saveRobustnessFieldsError,
  saveRobustnessFieldsSuccess,
  updateRobustnessFields,
  updateRobustnessMappingRow,
} from "../actions/robustness.actions";
import { EditAssessment } from "./edit-assessment";

export class RobustnessMappingRow extends Record({
  id: null,
  entryId: "",
  fileName: "",
  lineNumber: "",
  choiceType: "",
  choiceValue: "",
  choiceRange: "",
  source: "",
}) {}

export class ReasonableRobustnessCheck extends Record({
  id: null,
  analyticalChoiceIdentifier: "",
  analyticalChoiceVariation: "",
  variationSensibleExplanation: "",
  variationAffectValidityExplanation: "",
  testRedundancyConfirmation: "",
  results: "",
}) {}

export class ClaimRobustness extends Record({
  claimId: "",
  isRevisedReproductionPackageReferenced: "",
  revisedReproductionPackage: "",
  shortDescription: "",
  robustnessMappingRows: List.of(new RobustnessMappingRow()),
  reasonableRobustnessChecks: List.of(new ReasonableRobustnessCheck()),
}) {}

export class Robustness extends Record({
  isAuthor: "",
  workflowStage: "",
  shareableLink: true,
  claims: List.of(new ClaimRobustness()),
}) {}

export class EditRobustness extends Record({
  robustness: new Robustness(),
  saving: false,
  loading: false,
  notFound: false,
}) {}

function mergeRobustnessPayload(state: EditRobustness, payload: any) {
  const camelizedPayload = camelizeKeys(payload);

  camelizedPayload.claims =
    payload?.claims?.length > 0
      ? new List(
          camelizedPayload.claims.map((claim) => {
            claim.robustnessMappingRows =
              claim?.robustnessMappingRows?.length > 0
                ? new List(
                    claim.robustnessMappingRows.map(
                      (rmr) => new RobustnessMappingRow(rmr)
                    )
                  )
                : List.of(new RobustnessMappingRow());

            claim.reasonableRobustnessChecks =
              claim?.reasonableRobustnessChecks?.length > 0
                ? new List(
                    claim.reasonableRobustnessChecks.map(
                      (rrc) => new ReasonableRobustnessCheck(rrc)
                    )
                  )
                : List.of(new ReasonableRobustnessCheck());
            return new ClaimRobustness(claim);
          })
        )
      : new List();

  return state
    .set("robustness", state.robustness.merge(camelizedPayload))
    .set("loading", false);
}

const editRobustness = handleActions(
  {
    [updateRobustnessFields.toString()]: (
      state: EditRobustness,
      { payload }: any
    ) => {
      return state.set("robustness", state.robustness.merge(payload));
    },
    [updateRobustnessMappingRow.toString()]: (
      state: EditRobustness,
      { payload }: any
    ) => {
      return state.setIn(
        ["robustness", "claims"],
        state.robustness.claims.set(
          payload.claimIndex,
          state.robustness.claims
            .get(payload.claimIndex)
            .set(
              "robustnessMappingRows",
              state.robustness.claims
                .get(payload.claimIndex)
                .robustnessMappingRows.set(payload.rowIndex, payload.updatedRow)
            )
        )
      );
    },
    [deleteRobustnessMappingRow.toString()]: (
      state: EditAssessment,
      { payload }: any
    ) => {
      return setIn(
        state,
        ["robustness", "claims"],
        set(
          state.robustness.claims,
          payload.claimIndex,
          set(
            state.robustness.claims.get(payload.claimIndex),
            "robustnessMappingRows",
            state.robustness.claims
              .get(payload.claimIndex)
              .robustnessMappingRows.delete(payload.rowIndex)
          )
        )
      );
    },
    [fetchRobustness.toString()]: (state: EditRobustness) => {
      return state.set("loading", true);
    },
    [fetchRobustnessSuccess.toString()]: (
      state: EditRobustness,
      { payload }: any
    ) => {
      return mergeRobustnessPayload(state, payload);
    },
    [fetchRobustnessFailure.toString()]: (state: EditRobustness) => {
      return state.set("loading", false).set("notFound", true);
    },
    [saveRobustnessFields.toString()]: (state: EditRobustness) => {
      return state.set("saving", true);
    },
    [saveRobustnessFieldsSuccess.toString()]: (
      state: EditRobustness,
      { payload }: any
    ) => {
      return mergeRobustnessPayload(state.set("saving", false), payload);
    },
    [saveRobustnessFieldsError.toString()]: (state: EditRobustness) => {
      return state.set("saving", false);
    },
  },
  new EditRobustness()
);

export default editRobustness;
