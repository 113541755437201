import { createAction } from "redux-actions";
import { EditReproductionConstants } from "../constants/edit-reproduction.constants";

export const updateFields = createAction(
  EditReproductionConstants.UPDATE_FIELDS
);

export const updateTransposedEstimateRow = createAction(
  "updateTransposedEstimateRow"
);

export const fetchReproduction = createAction("fetchReproduction");

export const fetchReproductionSuccess = createAction(
  "fetchReproductionSuccess"
);
export const fetchReproductionFailure = createAction(
  "fetchReproductionFailure"
);

export const saveFields = createAction(EditReproductionConstants.SAVE_FIELDS);

export const saveFieldsSuccess = createAction(
  EditReproductionConstants.SAVE_FIELDS_SUCCESS
);

export const saveFieldsError = createAction(
  EditReproductionConstants.SAVE_FIELDS_ERROR
);
