import Typography from "@material-ui/core/Typography";
import React from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Box, Grid, Slider } from "@material-ui/core";

function QuestionWithSlider(props: {
  classes: ClassNameMap;
  readOnly: boolean;
  readOnlyLabel?: string;
  children: React.ReactNode;
  value: null;
  onChange: (e: any, value: number) => void;
  disabled?: boolean;
  valuesAndLabels: Array<{ label: string; value: string }>;
}) {
  const min = props.valuesAndLabels[0].value;
  const max = props.valuesAndLabels[props.valuesAndLabels.length - 1].value;

  let value = parseInt(props.value);
  if (!Number.isInteger(value)) {
    value = props.valuesAndLabels[0].value;
  }

  return (
    <>
      <Box mb={4}>
        <Typography
          variant="body1"
          component="div"
          className={(props.disabled
            ? [props.classes.typographyDisabled, props.classes.question]
            : [props.classes.question]
          ).join(" ")}
          gutterBottom
        >
          {props.children}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item>Low confidence</Grid>
        <Grid item xs>
          <Slider
            aria-labelledby="confidence-slider"
            valueLabelDisplay={props.readOnly ? "on" : "auto"}
            disabled={props.readOnly || props.disabled}
            value={value}
            onChange={props.onChange}
            step={1}
            marks={props.valuesAndLabels}
            min={min}
            max={max}
          />
        </Grid>
        <Grid item>High confidence</Grid>
      </Grid>
    </>
  );
}

export default QuestionWithSlider;
