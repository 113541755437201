import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { withStyles } from "@material-ui/core";

const StyledLink = withStyles((theme) => ({
  root: {
    color: `#0999cd`,
  },
}))(Link);

export default StyledLink;
