import React from "react";

import PapaParse from "papaparse";
import { decamelizeKeys } from "humps";
import { Button } from "gatsby-material-ui-components";
import { CloudUpload } from "@material-ui/icons";

interface Props {
  onDrop: (data: any, file?: any) => void;
  onError?: (err: any, file: any, inputElem: any, reason: any) => void;
}

interface State {
  file: any;
  timeout: any;
  files: any;
}

const DEFAULT_ACCEPT = "text/csv, .csv, application/vnd.ms-excel";

class CSVUploadButton extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    isReset: false,
  };

  inputFileRef: any = React.createRef<HTMLDivElement>();

  state = {
    file: null,
    timeout: null,
    files: null,
  } as State;

  handleDrop = (e: any) => {
    let files = null;

    if (e.files === undefined) {
      const dt = e.dataTransfer;
      files = dt.files;
    } else {
      files = e.files;
    }

    this.setState({ files }, () => {
      this.handleFiles();
    });
  };

  handleFiles = () => {
    let files = [...this.state.files];
    files.forEach(this.uploadFile);
  };

  uploadFile = (file: any) => {
    this.setState({ file });

    const { onDrop, onError, config = {} } = this.props;
    const reader = new window.FileReader();
    const data: any = [];

    let options = {
      complete: () => {
        onDrop(data, file);
      },
      step: (row: any) => {
        data.push(row);
      },
      header: true,
    };

    if (onError) {
      options = Object.assign({ error: onError }, options);
    }

    if (config) {
      options = Object.assign({}, config, options);
    }

    reader.onload = (e: any) => {
      PapaParse.parse(e.target.result, options);
    };

    reader.readAsText(file, "utf-8");
  };

  fileChange = (e: any) => {
    const { target } = e;
    this.handleDrop(target);
  };

  onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.inputFileRef.current.value = null;
    this.inputFileRef.current.click();
  };

  render() {
    return (
      <>
        <input
          type="file"
          accept={DEFAULT_ACCEPT}
          ref={this.inputFileRef}
          hidden
          onChange={(e) => this.fileChange(e)}
        />
        <Button
          startIcon={<CloudUpload />}
          variant={"contained"}
          color={"secondary"}
          onClick={this.onClick}
        >
          Upload CSV
        </Button>
      </>
    );
  }
}
export default CSVUploadButton;
